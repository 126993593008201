import React from 'react';
import tw, { styled } from 'twin.macro';

const Content = styled.div`
  ${tw`px-5 pb-5 overflow-y-auto`};
  max-height: calc(100vh - 200px);
`;

export const ModalContent: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => {
  return <Content {...rest}>{children}</Content>;
};
