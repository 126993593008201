import { createContext } from 'utils/react-helpers';

interface ModalContextValues {
  isOpen: boolean;
  id: string;
  onClose?: () => void;
  labelledby: string;
  describedby: string;
}

export const [ModalContextProvider, useModalContext] = createContext<
  ModalContextValues
>();
