import React from 'react';
import tw, { styled } from 'twin.macro';
import { Stack, StackJustify } from 'components/Stack';

interface Props extends React.HTMLAttributes<HTMLElement> {
  justify?: StackJustify;
}

const Footer = styled.footer`
  ${tw`border-t border-gray-300 h-16 flex px-5`};
`;

export const ModalFooter: React.FC<Props> = ({
  children,
  justify = 'end',
  ...rest
}) => {
  return (
    <Footer {...rest}>
      <Stack
        spacing={5}
        css={tw`w-full`}
        align="center"
        justify={justify}
        isInline
      >
        {children}
      </Stack>
    </Footer>
  );
};
