import React from 'react';
import { useModalContext } from './context';
import { callAllHandlers } from 'utils/function';
import { CloseButton, CloseButtonProps } from 'components/CloseButton';
import tw from 'twin.macro';

interface Props extends Omit<CloseButtonProps, 'label'> {
  label?: string;
}

export const ModalCloseButton = ({
  label = 'Đóng',
  size = 'base',
  onClick,
  ...props
}: Props) => {
  const { onClose } = useModalContext();

  return (
    <div
      css={[
        tw`absolute flex items-center justify-center top-0 right-0`,
        size === 'base' && tw`h-16 w-16`,
        size === 'sm' && tw`h-10 w-10`,
      ]}
    >
      <CloseButton
        size={size}
        label={label}
        {...props}
        onClick={callAllHandlers(onClick, () => {
          if (onClose) {
            onClose();
          }
        })}
      />
    </div>
  );
};
