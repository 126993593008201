/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { ModalContextProvider, useModalContext } from './context';
import { createGlobalStyle } from 'styled-components';
import { useId } from 'hooks/useId';

const GlobalStyle = createGlobalStyle`
  .ReactModal__Content{
    outline:none!important;
  }

  .ReactModal__Overlay {
    z-index:999;
    background-color: rgba(0, 0, 0, 0);
  }

  .ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .ReactModal__Overlay--before-close {
    background-color: rgba(0, 0, 0, 0);
  }
`;

ReactModal.defaultStyles = {};

export interface ModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen = true,
  onClose,
  children,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
}) => {
  const id = useId();
  const labelledby = `modal-title-${id}`;
  const describedby = `model-desc-${id}`;

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      ReactModal.setAppElement(root);
    }
  }, []);

  // TODO: this handle could be error-prone, in the long run
  // we might want to find a better solution for that
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <ModalContextProvider
      value={{ id, isOpen, onClose, labelledby, describedby }}
    >
      <GlobalStyle />
      <ReactModal
        closeTimeoutMS={400}
        shouldCloseOnEsc={shouldCloseOnEsc}
        onRequestClose={() => {
          if (onClose) {
            onClose();
          }
        }}
        style={{ content: {}, overlay: {} }}
        // This has been put in Purgecss whitelist. See ./tailwind.config.js
        overlayClassName="fixed inset-0 transition transition-all duration-300"
        isOpen={isOpen}
        aria={{ describedby, labelledby }}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        {children}
      </ReactModal>
    </ModalContextProvider>
  );
};

export { useModalContext };
