import React from 'react';
import { IconButton, IconButtonProps } from '../Button';
import IconClose from 'components/icons/IconClose';

export interface CloseButtonProps
  extends Omit<IconButtonProps, 'size' | 'Icon' | 'ButtonWrapper'> {
  size?: 'sm' | 'base';
}

export const CloseButton = ({
  label,
  size = 'base',
  ...rest
}: CloseButtonProps) => {
  return <IconButton {...rest} size={size} label={label} Icon={IconClose} />;
};
