import React from 'react';
import createIcon from './createIcon';

const IconClose = createIcon(
  <path
    d="M13.5909 12L18.3984 7.1925L19.3898 6.20109C19.5361 6.05484 19.5361 5.81719 19.3898 5.67094L18.3291 4.61016C18.1828 4.46391 17.9452 4.46391 17.7989 4.61016L12 10.4091L6.20109 4.60969C6.05484 4.46344 5.81719 4.46344 5.67094 4.60969L4.60969 5.67047C4.46344 5.81672 4.46344 6.05438 4.60969 6.20063L10.4091 12L4.60969 17.7989C4.46344 17.9452 4.46344 18.1828 4.60969 18.3291L5.67047 19.3898C5.81672 19.5361 6.05437 19.5361 6.20062 19.3898L12 13.5909L16.8075 18.3984L17.7989 19.3898C17.9452 19.5361 18.1828 19.5361 18.3291 19.3898L19.3898 18.3291C19.5361 18.1828 19.5361 17.9452 19.3898 17.7989L13.5909 12Z"
    fill="currentColor"
  />,
);

export default IconClose;
