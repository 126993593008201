import React from 'react';
import tw from 'twin.macro';
import { useModalContext } from './context';
import { Heading } from 'components/typography';

export const ModalHeader: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  ...rest
}) => {
  const { labelledby } = useModalContext();

  return (
    <header {...rest} css={tw`h-16 flex px-5 items-center`}>
      <Heading as="h3" id={labelledby} truncate>
        {children}
      </Heading>
    </header>
  );
};
