import React from 'react';
import tw, { styled } from 'twin.macro';
import { keyframes } from 'styled-components';
import { useModalContext } from './context';

type ModalBodySize = 'xs' | 'sm' | 'xl' | 'lg' | 'full';

const scale = keyframes`
    from {
        opacity: 0;
        transform: scale(0.5) translate(-50%, -50%);
    }
    to {
        transform: scale(1) translate(-50%, -50%);
        opacity:1;
    }
`;

const WithAnimation = styled.div<{
  containerSize: ModalBodySize;
  isOpen: boolean;
}>`
  animation: ${scale} 0.3s ease;
`;

const Container = styled(WithAnimation)((props) => {
  const styles = [
    tw`flex flex-col bg-white rounded-lg transition transition-all duration-300 opacity-0 absolute`,
    'width: 95%; left:50%; top: 50%; transform-origin: top left;',
    'transform: scale(0.5) translate(-50%, -50%);',
  ];

  if (props.isOpen) {
    styles.push('transform: scale(1) translate(-50%, -50%); opacity:1;');
  }

  switch (props.containerSize) {
    case 'xs':
      styles.push(tw`max-w-xs`);
      break;
    case 'sm':
      styles.push(tw`max-w-sm`);
      break;
    case 'xl':
      styles.push(tw`max-w-xl`);
      break;
    case 'lg':
      styles.push(tw`max-w-lg`);
      break;
  }

  return styles;
});

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: ModalBodySize;
}

export const ModalBody: React.FC<Props> = ({
  children,
  size = 'xl',
  ...rest
}) => {
  const { isOpen } = useModalContext();

  return (
    <Container containerSize={size} isOpen={isOpen} {...rest}>
      {children}
    </Container>
  );
};
